import React, { useEffect } from "react";
import { useState } from "react";
import { BASE_URL } from "../../../Helpers";
import "./userAvatar.sass";
const UserAvatar = ({ size, font, userData }) => {
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   if (userData.avatar || userData.avatar === null) {
  //     setLoading(false);
  //   }
  // }, [userData]);
  const name = (data) => {
    if (data.lastName.includes("apellido") || data.lastName.length < 1) {
      return data.firstName[0].toUpperCase();
    } else {
      return `${data.firstName[0].toUpperCase()}${data.lastName[0].toUpperCase()}`;
    }
  };

  const googleImage = () => {
    if (!loading) {
      if (
        userData.avatar === null ||
        userData?.avatar?.includes("ui-avatars.com")
      ) {
        return (
          <p
            style={{
              height: size ? size : "100%",
              width: size ? size : "100%",
              fontSize: font ? font : "1.5rem",
            }}
            className="p-2 userName"
          >
            {name(userData)}
          </p>
        );
      } else if (userData.avatar?.includes(`${BASE_URL}`)) {
        return (
          <div
            style={{
              height: size ? size : "100%",
              width: size ? size : "100%",
            }}
          >
            <img className="userAvatar" src={`${userData.avatar}`} alt="Foto" />
          </div>
        );
      } else if (userData.avatar?.includes("/userData/avatar/image")) {
        return (
          <div
            style={{
              height: size ? size : "100%",
              width: size ? size : "100%",
            }}
          >
            <img
              className="userAvatar"
              src={`${BASE_URL}/${userData.avatar}`}
              alt="Foto"
            />
          </div>
        );
      } else if (userData.avatar?.includes("image_")) {
        return (
          <div
            style={{
              height: size ? size : "100%",
              width: size ? size : "100%",
            }}
          >
            <img
              className="userAvatar"
              src={`${BASE_URL}/user/avatar/${userData.avatar}`}
              alt="Foto"
            />
          </div>
        );
      } else if (userData.avatar?.includes("lh3.googleusercontent.com")) {
        return (
          <div
            style={{
              height: size ? size : "100%",
              width: size ? size : "100%",
            }}
          >
            <img className="userAvatar" src={`${userData.avatar}`} alt="Foto" />
          </div>
        );
      } else {
        return (
          <p
            style={{
              height: size ? size : "100%",
              width: size ? size : "100%",
              fontSize: font ? font : "1.5rem",
            }}
            className="p-2 userName"
          >
            {name(userData)}
          </p>
        );
      }
    }
  };

  return <>{loading ? "" : googleImage()}</>;
};

export default UserAvatar;
