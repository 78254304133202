// Librerías
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { BASE_URL } from "../../Helpers";
// Componentes
import { closeModal } from "../../store/modal/actions";
// Recursos
import x from "../../assets/static/Modals/x.svg";
import "../../assets/styles/Modals/_messenger_modal.sass";
import AxiosAuth from "../../services/requestApi";
import loadingGif from "../../assets/static/loading.gif";
import useCurrentLanguage from "../../services/currentlenguaje";
import modalsJson from "./tradModals.json";
import axios from "axios";
import moment from "moment";

const MessengerModal = ({
  actionModal,
  housingId,
  userId,
  firstName,
  checkin,
  checkout,
  messageSistem,
}) => {
  const { handleFilterLang } = useCurrentLanguage();

  const [messenger, setMessenger] = useState("");
  const [sending, setSending] = useState(true);
  const [error, setError] = useState(false);
  const [sended, setSended] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageAuto, setMessageAuto] = useState(messageSistem);
  const dispatch = useDispatch();

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (housingId && userId && messenger.length > 0) {
      setSending(true);
      sendMessenger();
    }
  };

  useEffect(() => {
    const urlCost = BASE_URL + `/housing/${housingId}/reservations/cost`;
    const bodyDates = {
      id: housingId,
      checkin: moment(checkin, "D/M/YY").format("YYYY-MM-DD"),
      checkout: moment(checkout, "D/M/YY").format("YYYY-MM-DD"),
      guests: 1,
      coupon: null,
    };
    axios
      .post(urlCost, bodyDates)
      .then((response) => {
        sendMessenger();
      })
      .catch((error) => {
        setMessageAuto(false);
      });
  }, []);

  const sendMessenger = async () => {
    setSending(false);
    setLoading(true);
    const url = BASE_URL + "/user/messages";
    const bodyParameters = {
      messageSistem:
        messageAuto &&
        `¡Hola ${firstName}!, Estoy interesado/a en alojarme en tu propiedad desde el ${checkin} hasta el  ${checkout}. ¿Está disponible para esas fechas? `,
      message: !messageAuto && messenger,
      to: {
        id: userId,
      },
      hosting: {
        id: housingId,
      },
    };
    const send = await AxiosAuth.post(url, bodyParameters)
      .then((data) => {
        setSending(false);
        setMessenger("");
        setSended(true);
        setLoading(false);
        window.location.href = "/messengers";
      })
      .catch((e) => {
        setLoading(false);
        setSending(false);
        setError(true);
        console.log(e);
      });

    return send;
  };

  return (
    <div className="MessengerModal">
      <header className="MessengerModal__header">
        <p className="MessengerModal__header__title">
          {handleFilterLang(modalsJson).comunicateCon}
          {firstName}
        </p>
        <img
          className="MessengerModal__header__cross"
          onClick={() => dispatch(closeModal())}
          alt="x"
          src={x}
        />
      </header>
      <section className="MessengerModal__section">
        <form className="MessengerModal__section__form" onSubmit={handleSubmit}>
          {loading && (
            <img
              style={{ position: "static" }}
              className="Checkout__loading"
              alt="loading"
              src={loadingGif}
            />
          )}
          {sended && (
            <>
              <p className="MessengerModal__section__form__title">
                {handleFilterLang(modalsJson).mensajeEnviado}
              </p>
              <br />
              <p className="MessengerModal__section__form__text">
                ¡{firstName}
                {handleFilterLang(modalsJson).recibioTuConsulta}
              </p>
              <p className="MessengerModal__section__form__text">
                {handleFilterLang(modalsJson).verMensaje}
              </p>
              <br />
              <p className="MessengerModal__section__form__text">
                <strong>{handleFilterLang(modalsJson).atencion}</strong>{" "}
                {handleFilterLang(modalsJson).cuandoResponda}
                {firstName},{handleFilterLang(modalsJson).recibirasMail}
              </p>

              <button
                onClick={() => dispatch(closeModal())}
                className="MessengerModal__section__form__btn"
              >
                {handleFilterLang(modalsJson).cerrar}
              </button>
            </>
          )}
          {sending && (
            <>
              <p className="MessengerModal__section__form__title">
                {firstName}
                {handleFilterLang(modalsJson).suele}

                <br />
                {handleFilterLang(modalsJson).responder}
              </p>
              <br />
              <p className="MessengerModal__section__form__text">
                {handleFilterLang(modalsJson).pregunta} {firstName}?
              </p>
              <br />
              <textarea
                className="MessengerModal__section__form__textarea"
                value={messenger}
                onChange={(e) => setMessenger(e.target.value)}
              />
              <button
                type="submit"
                className="MessengerModal__section__form__btn"
              >
                {handleFilterLang(modalsJson).enviar}
              </button>
            </>
          )}
          {error && (
            <div
              style={{ height: "200px", display: "flex", alignItems: "center" }}
            >
              <p className="MessengerModal__header__title">
                {handleFilterLang(modalsJson).errorEnviarMensaje}
              </p>
            </div>
          )}
        </form>
      </section>
    </div>
  );
};

export default connect(null, { closeModal })(MessengerModal);
