import { BASE_URL } from "../../Helpers";

export const loginUser =
  ({ token, refresh_token, firstName, lastName, avatar, email, id }) =>
  (dispatch) => {
    avatar = avatar.includes("http") ? avatar : BASE_URL + avatar;

    return dispatch({
      type: "LOGIN_USER",
      token,
      refresh_token,
      firstName,
      lastName,
      avatar,
      id,
      email,
    });
  };

export const setToken = (token) => (dispatch) => {
  return dispatch({
    type: "SET_TOKEN",
    token: token,
  });
};

export const nextStep = () => (dispatch) => {
  dispatch({
    type: "NEXT_STEP",
  });
};

export const logoutUser = () => {
  return {
    type: "LOGOUT_USER",
  };
};

export const redirectUser = (redirect) => (dispatch) => {
  dispatch({
    type: "REDIRECT_USER",
    redirect,
  });
  return Promise.resolve();
};

export const cleanRedirectUser = () => {
  return {
    type: "CLEAN_REDIRECT_USER",
  };
};

export const setUserAvatar = (avatar) => {
  const user_data = JSON.parse(localStorage.getItem("userData"));
  user_data.avatar = avatar;
  localStorage.setItem("userData", JSON.stringify(user_data));
  avatar = avatar.includes("http") ? avatar : BASE_URL + avatar;
  return {
    type: "AVATAR",
    avatar: avatar,
  };
};

export const setFcmToken = (token) => {
  return {
    type: "SET_FCM_TOKEN",
    token: token,
  };
};

export const cleanFcmToken = () => {
  return {
    type: "CLEAN_FCM_TOKEN",
  };
};

export const SetFcmTokenSended = () => {
  return {
    type: "SET_FCM_TOKEN_SENDED",
  };
};

export const CleanFcmTokenSended = () => {
  return {
    type: "CLEAN_FCM_TOKEN_SENDED",
  };
};
