// Librerías
import React, { Component } from "react";
import { connect } from "react-redux";
// Recursos
import "../../../assets/styles/AloharForm/Utils/_progress_bar.sass";

class ProgressBar extends Component {
  render() {
    const allSteps = [];
    for (let i = 0; i < 14; i++) {
      allSteps[i] = i;
    }
    const progressBarWidth = (100 / 14) * (this.props.step - 2);
    const progressBarFinished = (progressBarWidth, step, id) => {
      let actualStep = step - 2;
      if (id <= actualStep) {
        if (actualStep === 14) {
          return {
            width: `100%`,
            background: "#38D1DA",
          };
        } else {
          return {
            width: `100%`,
          };
        }
      } else {
        return {
          width: `0%`,
        };
      }
    };
    const progressBars = (progressBarWidth, step, title) => {
      let list = allSteps.map((id) => {
        return (
          <div className="ProgressBar__bar">
            <span
              className="ProgressBar__bar__progress"
              key={id}
              style={progressBarFinished(progressBarWidth, this.props.step, id)}
            ></span>
          </div>
        );
      });
      return list;
    };
    return (
      <React.Fragment>
        <div className="ProgressBar">
          <div className="ProgressBar__container">
            <div className="ProgressBar__container__titles">
              <h2 className="ProgressBar__container__titles-title">
                {this.props.title}
              </h2>
              <div className="ProgressBar__bars">
                {progressBars(progressBarWidth, this.props.step)}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (reducers) => {
  return reducers.aloharFormReducer;
};

export default connect(mapStateToProps)(ProgressBar);
