// Librerías
import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { openLoginModal } from "../../../store/modal/actions";
import axios from "axios";
import { connect } from "react-redux";
import { BASE_URL } from "../../../Helpers";
// Componentes
import SelectDate from "../../MainSearch/SelectDate";
import SelectPersons from "../../MainSearch/SelectPersons.js";
// Recursos
import * as moment from "moment";
import { Modal } from "antd";
import "antd/dist/antd.css";
import "../../../assets/styles/PropertyPage/Utils/_search.sass";
import { showDisabledDate } from "../../../hooks/showDisabledDate";
import ButtonPrimary from "../../ui/button-primary";
import useCurrentLanguaje from "../../../services/currentlenguaje.js";
import globalJson from "../../../utilities/traduccGlobal.json";

const cancelButtonStyle = {
  shape: "round",
};

const okButtonStyle = {
  shape: "round",
  className: "bg-primary",
};

const Search = ({
  housingId,
  servicePrice,
  price,
  cleanPrice,
  totalPrice,
  realPrice,
  openLoginModal,
  loading,
  title,
  monthlyDiscount,
  weeklyDiscount,
  typeHousingReservation,
  timeAdvanceBook,
  clearPrice,
  previaje,
  taxes,
  error,
  idReceptor,
  handleActionModal,
  housingPromotions,
}) => {
  const { handleFilterLang } = useCurrentLanguaje();

  const [disableDates, setDisableDates] = useState();
  const [hotSaleDates, setHotSaleDates] = useState();
  const [_loading, set_loading] = useState(loading);
  const [getDates, setGateDates] = useState(false);
  const searchState = useSelector((state) => state.searchReducer, shallowEqual);
  const userState = useSelector((state) => state.userReducer, shallowEqual);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  // Modal Things
  const [visible, setVisible] = useState(false);
  let history = useHistory();
  const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
  const NOW = moment().format(DATE_FORMAT);
  const TO = moment("2022-01-03 10:00:00").format(DATE_FORMAT);

  let { adults, checkin, checkout, childs } = searchState;
  const validFrom = housingPromotions && moment(housingPromotions.valid_from);
  const validTo = housingPromotions && moment(housingPromotions.valid_to);
  const dateToday = moment();

  useEffect(() => {
    if (_loading !== loading) set_loading(loading);
    if (!getDates) {
      const urlCalendar = `${BASE_URL}/housing/publicCalendar/${housingId}`;
      axios
        .get(urlCalendar)
        .then((response) => {
          const diablesDates = response.data.calendar.calendar
            .filter(
              (c) =>
                c.typeHousingCalendar !== "available" &&
                c.typeHousingCalendar !== "season" &&
                c.typeHousingCalendar !== "hot_sale"
            )
            .map((c) => {
              c.end = moment(c.end)
                .utc(true)
                .add(response.data.timeAdvanceBook, "days")
                .format("YYYY-MM-DD");
              return c;
            });

          const hotSale = response.data.calendar.calendar.filter(
            (c) => c.typeHousingCalendar === "hot_sale"
          );
          if (dateToday.isBetween(validFrom, validTo)) {
            setHotSaleDates(hotSale);
          }
          const distable_today = {
            end: moment
              .utc()
              .add(timeAdvanceBook, "days")
              .startOf("today")
              .format("YYYY-MM-DD"),
            price: 0,
            start: moment().utc().format("YYYY-MM-DD"),
            typeHousingCalendar: "block",
          };
          const hot_sale_today = {
            end: moment
              .utc()
              .add(timeAdvanceBook, "days")
              .startOf("today")
              .format("YYYY-MM-DD"),
            price: 0,
            start: moment().utc().format("YYYY-MM-DD"),
            typeHousingCalendar: "hot_sale",
          };
          if (timeAdvanceBook > 0) diablesDates.push(distable_today);
          setDisableDates(diablesDates);
          if (timeAdvanceBook > 0) hotSaleDates.push(hot_sale_today);
        })
        .catch((e) => {
          console.log(e);
        });
      setGateDates(true);
    }
  });
  useEffect(() => {
    if (checkin && checkout && disableDates) {
      const viewDisabledDate = false;
      const view = showDisabledDate(disableDates, checkin, checkout);
      if (view) {
        setButtonDisabled(true);
      } else {
        setButtonDisabled(false);
      }
    }
  }, [checkin, checkout, disableDates]);
  return (
    <React.Fragment>
      <div className="Booking">
        <div className="Booking__container">
          <div className="Booking__dates">
            <SelectDate
              checkin={checkin}
              checkout={checkout}
              disableDates={disableDates}
              hotSaleDates={hotSaleDates}
              clearPrice={clearPrice}
            />
          </div>
          <div className="Booking__people">
            <SelectPersons childs={childs} adults={adults} />
          </div>
        </div>

        {error === 0 ? (
          <ButtonPrimary
            width={"100%"}
            title={handleFilterLang(globalJson).consultarDisponibilidad}
            onClick={() => {
              handleActionModal("sistem");
            }}
          />
        ) : (
          <button
            disabled={
              totalPrice === 0 || error || buttonDisabled ? true : false
            }
            className={
              totalPrice === 0 || error || buttonDisabled
                ? "Booking__disabled"
                : "Book__cta"
            }
            onClick={(e) => {
              if (_loading) e.preventDefault();
              if (!checkin || !checkout) e.preventDefault();
              if (!userState.login) openLoginModal();
              // NOW >= FROM &&
              if (NOW <= TO) {
                setVisible(!visible);
              } else {
                history.push(
                  `/property/${housingId}/checkout?id=${housingId}&servicePrice=${servicePrice}&price=${price}&cleanPrice=${cleanPrice}&totalPrice=${totalPrice}&checkin=${checkin}&checkout=${checkout}&persons=${
                    adults + childs
                  }&housingTitle=${title}&monthlyDiscount=${monthlyDiscount}&weeklyDiscount=${weeklyDiscount}&realPrice=${realPrice}&typeHousingReservation=${typeHousingReservation}&previaje=${previaje}&taxes=${taxes}&idReceptor=${idReceptor}`
                );
              }
            }}
          >
            {_loading
              ? handleFilterLang(globalJson).calculando
              : handleFilterLang(globalJson).reservar}
          </button>
        )}

        {/* Modal */}
        <Modal
          title={"Aviso importante sobre PREVIAJE"}
          visible={visible}
          onCancel={() => setVisible(!visible)}
          onOk={() =>
            history.push(
              `/property/${housingId}/checkout?id=${housingId}&servicePrice=${servicePrice}&price=${price}&cleanPrice=${cleanPrice}&totalPrice=${totalPrice}&checkin=${checkin}&checkout=${checkout}&persons=${
                adults + childs
              }&housingTitle=${title}&monthlyDiscount=${monthlyDiscount}&weeklyDiscount=${weeklyDiscount}&realPrice=${realPrice}&typeHousingReservation=${typeHousingReservation}&previaje=${previaje}&taxes=${taxes}`
            )
          }
          cancelButtonProps={cancelButtonStyle}
          okButtonProps={okButtonStyle}
          cancelText={"Cancelar"}
          okText={"Entiendo y quiero reservar"}
        >
          <h2>¡Hola {userState.firstName}!</h2>
          <p>
            Queríamos avisarte que para enviarte la factura de tu compra para
            previaje es requisito que cargues nombre completo,{" "}
            <b>CUIT y dirección</b> sin eso no podremos emitir tu <b>factura</b>
            !
          </p>
          <br />
          <p>¡No te olvides, de esa info!</p>
          <br />
          <p>
            Acordate podes contactarnos a nuestro WhatsApp{" "}
            <a
              href="https://api.whatsapp.com/send?phone=5492233018749"
              target="_blank"
              rel="noopener noreferrer"
            >
              +54 9 223 301 8749
            </a>
            .
          </p>
        </Modal>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (reducers) => {
  return reducers.modalReducer;
};

export default connect(mapStateToProps, { openLoginModal })(Search);
