import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import esLocale from "moment/locale/es";
import moment from "moment";
import "react-dates/initialize";
import { setCheckin, setCheckout } from "../../store/search/actions";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import hotSale from "../../../src/assets/static/hot_sale/alohar-hot-sale.png";
import "../../assets/styles/MainSearch/daterange.sass";
import tradMainSearch from "./tradMainSearch.json";
import useCurrentLanguaje from "../../services/currentlenguaje";

const SelectDate = (props) => {
  const { handleFilterLang } = useCurrentLanguaje();
  const dispatch = useDispatch();
  moment.updateLocale("es", esLocale);

  const initialCheckin =
    props.checkin === "" ? null : moment(props.checkin, "DD/MM/YY").utc(true);
  const initialCheckout =
    props.checkout === "" ? null : moment(props.checkout, "DD/MM/YY").utc(true);

  const [startDate, setStartDate] = useState(initialCheckin);
  const [endDate, setEndDate] = useState(initialCheckout);
  const [focusedInput, setFocusedInput] = useState(null);
  const [lenguaje, setLenguaje] = useState(props.lenguaje || "es");
  const [orientation] = useState("horizontal");
  const [months] = useState(1);
  const [initDataFromURL, setInitDataFromURL] = useState(false);
  const [disableDates, setDisableDates] = useState(props.disableDates);
  const [hotSaleDates, setHotSaleDates] = useState(props.hotSaleDates);
  const [highlightedDates] = useState([
    "2024-05-01",
    "2024-05-10",
    "2024-05-20",
    "2024-05-25",
  ]);

  useEffect(() => {
    if (props.lenguaje !== lenguaje) {
      setLenguaje(props.lenguaje);
    }

    if (props.setStartDate) {
      props.setStartDate(startDate);
    }

    if (props.setEndDate) {
      props.setEndDate(endDate);
    }

    if (props.disableDates !== disableDates) {
      setDisableDates(props.disableDates);
    }

    if (props.hotSaleDates !== hotSaleDates) {
      setHotSaleDates(props.hotSaleDates);
    }

    if (!startDate && !endDate && !initDataFromURL) {
      if (props.checkin !== "" && props.checkout !== "") {
        const parsedCheckin = moment(
          props.checkin,
          props.newStyle ? "DD-MM-YY" : "DD/MM/YY"
        ).utc(true);
        const parsedCheckout = moment(
          props.checkout,
          props.newStyle ? "DD-MM-YY" : "DD/MM/YY"
        ).utc(true);
        setStartDate(parsedCheckin);
        setEndDate(parsedCheckout);
        setInitDataFromURL(true);
      }
    }
  }, [
    props,
    startDate,
    endDate,
    lenguaje,
    initDataFromURL,
    disableDates,
    hotSaleDates,
  ]);

  const disabledDates = (day) => {
    if (disableDates) {
      const ifBlock = disableDates.filter((d) => {
        const start = d.start;
        const end = d.end;
        return day.isBetween(start, end, "days", "[]");
      });
      return ifBlock.length !== 0;
    }
    return false;
  };

  const iconHotSaleDates = (day) => {
    if (hotSaleDates) {
      const ifBlock = hotSaleDates.filter((d) => {
        const start = d.start;
        const end = d.end;
        return day.isBetween(start, end, "days", "[]");
      });
      return ifBlock;
    } else {
      return null;
    }
  };

  const onDatesChange = ({ startDate, endDate }) => {
    if (props.setStartDate) {
      props.setStartDate(startDate);
    }

    if (props.setEndDate) {
      props.setEndDate(endDate);
    }

    setStartDate(startDate);
    setEndDate(endDate);

    if (startDate) dispatch(setCheckin(startDate.format("DD/MM/YY")));
    if (endDate) dispatch(setCheckout(endDate.format("DD/MM/YY")));

    if (startDate === null && endDate === null) {
      setStartDate("");
      setEndDate("");
      dispatch(setCheckin(""));
      dispatch(setCheckout(""));
      props.clearPrice();
    }
  };
  console.log("hotSaleDates");
  console.log(hotSaleDates);

  return (
    <div
      className={`${
        props.newStyle ? "input_wrapperNew w-full sm:h-max" : "input_wrapper"
      } `}
      id="select_date"
    >
      <DateRangePicker
        renderDayContents={(day) => {
          if (hotSaleDates) {
            if (iconHotSaleDates(day).length > 0) {
              return (
                <div className="relative">
                  {day.format("D")}
                  <img
                    src={hotSale}
                    alt="Hot sale"
                    className="absolute top-[-4px] right-[0] z-50 w-2"
                  />
                </div>
              );
            }
          }
          return day.format("D");
        }}
        startDate={startDate}
        startDateId="Llegada"
        endDate={endDate}
        endDateId="Salida"
        onDatesChange={onDatesChange}
        focusedInput={focusedInput}
        onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
        startDatePlaceholderText={handleFilterLang(tradMainSearch).desde}
        endDatePlaceholderText={handleFilterLang(tradMainSearch).hasta}
        orientation={orientation}
        numberOfMonths={months}
        noBorder
        hideKeyboardShortcutsPanel
        small
        showClearDates={true}
        displayFormat={props.format ? props.format : "DD MMM"}
        isDayBlocked={disabledDates}
      />
    </div>
  );
};

SelectDate.defaultProps = {
  checkin: "",
  checkout: "",
};

export default SelectDate;
