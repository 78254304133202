// Librerías
import React, { useState, useEffect } from "react";
import queryString from "query-string";
import { Link } from "react-router-dom";
// Components
import CardSlider from "./CardSlider";
// Assets
import * as moment from "moment";
import axios from "axios";
import { BASE_URL } from "../../../Helpers";
import loadingGif from "../../../assets/static/loading.gif";
import "../../../assets/styles/SearchPage/Utils/_search_card.sass";
import medallaPlata from "../../../assets/static/housing-promotions/medalla-plata.png";
import useCurrentLanguaje from "../../../services/currentlenguaje";
import untilsJson from "../Utils/tradUntils.json";
import globalJson from "../../../utilities/traduccGlobal.json";

const SearchCard = ({
  id,
  img,
  status,
  housingMedia,
  amount,
  title,
  guests,
  bedrooms,
  beds,
  bathrooms,
  services,
  type,
  rate,
  neighborhood,
  description,
  user,
  // previaje,
  bookingStart,
  bookingEnd,
  vertical,
  disabledSlider,
  hotSale,
  city,
  location,
}) => {
  const { handleFilterLang } = useCurrentLanguaje();
  const [link, setLink] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [loading, setLoading] = useState(true);
  const [datePrice, setDatePrice] = useState();
  const [error, setError] = useState(false);
  const clearPrice = () => {
    setTotalPrice(0);
  };

  const formatDate = (dateCalendar) => {
    const arrayDate =
      dateCalendar === undefined ? false : dateCalendar.split("/");
    return arrayDate.length === 3 ? arrayDate : false;
  };

  const formatDateMonth = (format) => {
    const arrayDate = format === undefined ? false : format.split("/");
    return arrayDate.length === 3 ? arrayDate[1] : false;
  };

  const truncar = (num, digitos) => {
    const re = new RegExp("(\\d+\\.\\d{" + digitos + "})(\\d)");
    const _num = num.toString().match(re);
    return _num ? _num[1] : num;
  };

  const getUrlParams = () => {
    const query = queryString.parse(window.location.search);
    const { checkin, checkout, adults, childs } = query;

    if (checkin && checkout) {
      setLink(
        `property/${id}?city=${city}&checkin=${checkin}&checkout=${checkout}&childs=${childs}&adults=${adults}`
      );
    } else {
      setLink(`property/${id}?city=${city}&childs=${childs}&adults=${adults}`);
    }
  };

  const getPrices = async () => {
    const url = BASE_URL + `/housing/${id}/reservations/cost`;
    const bodyParameters = {
      id: id,
      checkin: moment(bookingStart, "D/M/YY").format("YYYY-MM-DD"),
      checkout: moment(bookingEnd, "D/M/YY").format("YYYY-MM-DD"),
      guests: 1,
    };
    await axios
      .post(url, bodyParameters)
      .then((data) => {
        setLoading(true);
        setTotalPrice(truncar(data.data.totalPrice, 2));
      })
      .catch((e) => {
        console.log(e);
        if (e.response.status === 400) {
          clearPrice();
        }
        if (e.response.status === 500) {
          setError(true);
        }
      })
      .then(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (bookingStart === undefined || 0 || null) {
      setLoading(false);
    } else {
      getPrices();
    }
    getUrlParams();
    compareDate();
  }, []);

  const compareDate = () => {
    const start = moment(bookingStart, "DD/MM/YY");
    const end = moment(bookingEnd, "DD/MM/YY");
    const differenceInDays = end.diff(start, "days");
    setDatePrice(differenceInDays);
  };
  const dateToday = moment();
  const validFrom = hotSale && moment(hotSale.valid_from);
  const validTo = hotSale && moment(hotSale.valid_to);
  const validHotsale = dateToday.isBetween(validFrom, validTo);
  const validFromHeighlight = hotSale && moment(hotSale.valid_from_heighlight);
  const validToHeighlight = hotSale && moment(hotSale.valid_to_heighlight);
  const valideighlight = dateToday.isBetween(
    validFromHeighlight,
    validToHeighlight
  );

  return (
    <>
      <div
        className={`py-4 px-2 relative ${
          vertical ? `flex flex-col w-auto` : ""
        }   SmallCard `}
      >
        <CardSlider
          disabledSlider={disabledSlider}
          cover={img}
          housingMedia={housingMedia}
          hotSale={validHotsale}
          status={hotSale.status}
          valideighlight={valideighlight}
          housingPromotions={hotSale && hotSale}
        />
        <Link to={link} target="_blank">
          <div className=" SmallCard__infocontainer">
            <div className="SmallCard__infocontainer__data">
              <div className="SmallCard__infocontainer__data__type">{type}</div>
              <div className="SmallCard__infocontainer__data__title">
                {title}
              </div>

              {validHotsale && (
                <div
                  className="SmallCard__infocontainer__data__description"
                  style={{ textTransform: "capitalize" }}
                >
                  {`${location.location}  ${
                    neighborhood ? `- ${neighborhood?.name}` : ""
                  }`}
                </div>
              )}
              <div className="SmallCard__infocontainer__data__description">
                {guests > 1
                  ? `${guests} 
                ${handleFilterLang(globalJson).huespedes}
                `
                  : `${guests}
                ${handleFilterLang(globalJson).huesped} `}{" "}
                ·{" "}
                {bedrooms > 1
                  ? `${bedrooms} 
                  ${handleFilterLang(globalJson).dormitorios}
                  `
                  : `${bedrooms} 
                  ${handleFilterLang(globalJson).dormitorio}
                  `}{" "}
                ·{" "}
                {beds > 1
                  ? `${beds} 
                ${handleFilterLang(globalJson).camas}
                `
                  : `${beds} 
                ${handleFilterLang(globalJson).cama}
                `}{" "}
                ·{" "}
                {bathrooms > 1
                  ? `${bathrooms} 
                ${handleFilterLang(globalJson).baños}
                `
                  : `${bathrooms} 
                ${handleFilterLang(globalJson).baño}
                `}
              </div>
              <div className="SmallCard__infocontainer__data__review">
                <div className="SmallCard__infocontainer__data__review__header">
                  <div className="flex items-center gap-2 SmallCard__infocontainer__data__review__header__name">
                    {valideighlight &&
                      hotSale.priorityLevel.name === "platinum" &&
                      hotSale.priorityLevel.priority === 2 && (
                        <img
                          className="w-4 "
                          src={medallaPlata}
                          alt="Medalla plateada"
                        />
                      )}
                    {user} dice:
                  </div>
                </div>
                <div className="SmallCard__infocontainer__data__review__text">
                  {description
                    ? description.substring(0, 250)
                    : handleFilterLang(untilsJson).sinDescripcion}
                  {description && description.length > 250 ? "..." : ""}
                </div>
              </div>
            </div>
            <div className="SmallCard__infocontainer__price">
              {loading ? (
                <img
                  className="SmallCard__infocontainer__price__loading"
                  alt="Cargando..."
                  src={loadingGif}
                />
              ) : error ? (
                <div className="mx-auto SmallCard__infocontainer__price__reviews ">
                  <p className="SmallCard__infocontainer__price__reviews__number">
                    {handleFilterLang(globalJson).verDetalle}
                  </p>
                </div>
              ) : (
                <p className="flex flex-col SmallCard__infocontainer__price__amount">
                  <p className="flex flex-col items-start gap-1 SmallCard__infocontainer__price__amount__numbers">
                    {totalPrice !== 0 && (
                      <span className="text-xs text-primary">
                        {validHotsale ? (
                          <>
                            {`${hotSale?.discount} %OFF`}
                            <br />
                            {handleFilterLang(globalJson).fechasSeleccionadas}
                          </>
                        ) : (
                          ""
                        )}
                      </span>
                    )}
                    {totalPrice === 0
                      ? " "
                      : " ARS$ " +
                        totalPrice
                          .toString()
                          .split(/(?=(?:\d{3})+(?:,|$))/g)
                          .join(".")}
                  </p>
                  <p className="SmallCard__infocontainer__price__amount__pernight">
                    {totalPrice === 0 ? (
                      <>
                        (*){handleFilterLang(untilsJson).ingreseFecha}
                        <br />
                        {handleFilterLang(untilsJson).paraConocerPrecio}
                      </>
                    ) : (
                      `${
                        handleFilterLang(globalJson).precioTotalPor
                      } ${datePrice} ${handleFilterLang(globalJson).noches}`
                    )}
                  </p>
                </p>
              )}

              {!error && totalPrice !== 0 && (
                <div className="SmallCard__infocontainer__price__reviews">
                  <p className="SmallCard__infocontainer__price__reviews__number">
                    {handleFilterLang(globalJson).reservar}
                  </p>
                </div>
              )}
              {!error && totalPrice === 0 && (
                <div className="SmallCard__infocontainer__price__reviews">
                  <p className="SmallCard__infocontainer__price__reviews__number">
                    {handleFilterLang(globalJson).consultarPrecio}
                  </p>
                </div>
              )}
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default SearchCard;
